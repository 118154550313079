import api from "../api";

export const GetApplicationFee = async () => {
    try {
        const { data } = await api.get(`/setup/view/fees/`)

        return data;
    } catch (error) {
        throw error.response.data;
    }
}
export const GetPaymentStatus = async (id) => {
    try {
        const { data } = await api.get(`/paystack/fetch-payments/${id}`)

        return data;
    } catch (error) {
        throw error.response.data;
    }
}
export const GetApplicantDetails = async (id) => {
    try {
        const { data } = await api.get(`/admissions/applicant/view/${id}`)

        return data;
    } catch (error) {
        throw error.response.data;
    }
}
export const GetApplicantOlevel = async (id) => {
    try {
        const { data } = await api.get(`/admissions/applicant/olevel/${id}`)

        return data;
    } catch (error) {
        throw error.response.data;
    }
}
export const InitiateApplicationPayment = async ({application_id}) => {
    try {
        const { data } = await api.post(`/paystack/application-fee`, {application_id})

        return data;
    } catch (error) {
        throw error.response.data;
    }
}
export const InitiateAcceptancePayment = async ({admission_no}) => {
    try {
        const { data } = await api.post(`/paystack/acceptance-fee`, {admission_no})

        return data;
    } catch (error) {
        throw error.response.data;
    }
}

export const InitiateResultCheckingPayment = async ({application_id}) => {
    try {
        const { data } = await api.post(`/paystack/results-checking-fee`, {application_id})

        return data;
    } catch (error) {
        throw error.response.data;
    }
}
export const InitiateProspectiveResultCheckingPayment = async ({admission_no}) => {
    try {
        const { data } = await api.post(`/paystack/prospective-results`, {admission_no})

        return data;
    } catch (error) {
        throw error.response.data;
    }
}

export const VerifyApplicationPayment = async (ref) => {
    try {
        const { data } = await api.get(`/paystack/verify/${ref}`)

        return data;
    } catch (error) {
        throw error.response.data;
    }
}

export const GetPaymentInvoice = async (id) => {
    try {
        const { data } = await api.get(`/paystack/fetch-payments/${id}`)

        return data;
    } catch (error) {
        throw error.response.data;
    }
}

// Admission endpoints

export const GetProspectivePayment = async (id) => {
    try {
        const { data } = await api.get(`/prospectives/all-payments/${id}`)

        return data;
    } catch (error) {
        throw error.response.data;
    }
}
export const GetUploadedDocuments = async (id) => {
    try {
        const { data } = await api.get(`/prospectives/view-docs/${id}`)

        return data;
    } catch (error) {
        throw error.response.data;
    }
}

export const GetProspectiveDetails = async (id) => {
    try {
        const { data } = await api.get(`/prospectives/view-details/${id}`)

        return data;
    } catch (error) {
        throw error.response.data;
    }
}
export const GetProspectiveStage = async (id) => {
    try {
        const { data } = await api.get(`/prospectives/view/${id}`)

        return data;
    } catch (error) {
        throw error.response.data;
    }
}

export const InitiateChangeOfCoursePayment = async ({admission_no}) => {
    try {
        const { data } = await api.post(`/paystack/change-of-course`, {admission_no})

        return data;
    } catch (error) {
        throw error.response.data;
    }
}
