import React, { UseEffect, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../api/api";
import Footer from "../../components/Footer";
import Logo from "../../components/Logo";
import { setCurrentUser } from "../../components/services/auth/authService";
import { asyncLocalStorage } from "../../utils/appParams";
import NavHeader from "./components/NavHeader";

const ApplicationResetPassword = () => {
  const [email, setEmail] = useState();
  const [redirectUrl, setRedirectUrl] = useState('http://localhost:3000')
  const [btnText, setBtnText] = useState("Reset");
  const [loading, setLoading] = useState(false);
  const [alertClose,setAlertClose] = useState(false)

  const navi = useNavigate();

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    setBtnText('Loading...')
    setLoading(true)
    try {
        const {data} = await api.post('/password/request-reset-email/', {
            email,
            redirect_url: redirectUrl
        })
        console.log(data)
        setAlertClose(true)
        setBtnText("Reset")
        toast.success(data?.success)
        setLoading(false)
    } catch (error) {
      console.log(error);
      setBtnText("Failed, try again")
      setLoading(false)
      toast.error(error?.error, errorProp)
    }
  };

  return (
    <>
      <NavHeader />
      <div class="account-body">
        <div class="container">
          <div class="row vh-100 ">
            <div class="col-12 align-self-center">
              <div class="auth-page">
                <div class="card auth-card shadow-lg">
                  <div class="card-body">
                    <div class="px-3">
                      <div class="text-center auth-logo-text">
                        <h4 class="mt-0 mb-3 mt-5">Forgot Password</h4>
                        <p class="text-muted mb-0">
                          Enter your Email and instructions will be sent to you!
                        </p>
                      </div>

                      <form
                        class="form-horizontal auth-form my-4"
                        onSubmit={resetPassword}
                      >
                        <div class="form-group">
                        {alertClose && 
                        <div className="alert dspg-info alert-dismissible fade show">
                        <h4 class="alert-heading">Check your email</h4>
                          <p>We sent a password reset link to your email</p>
                          <button type="button" class="close" data-dismiss="alert" aria-label="Close" onClick={() => setAlertClose(false)}>
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                        }
                          <label for="username">Email</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-mail"></i>
                            </span>
                            <input
                              type="email"
                              class="form-control"
                              placeholder="Enter email address"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div class="form-group mb-0 row">
                          <div class="col-12 mt-2">
                            <button
                              class="btn btn-gradient-primary btn-round btn-block waves-effect waves-light"
                              type="submit"
                              disabled={loading}
                            >
                              {btnText} <i class="fas fa-sign-in-alt ml-1"></i>
                            </button>
                          </div>
                        </div>
                        <ToastContainer />
                      </form>
                    </div>

                    <div class="m-3 text-center text-muted">
                      <p class="">
                        Remember it ?{" "}
                        <Link to="/apply/login" class="text-primary ml-2">
                          Sign in here
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationResetPassword;
