import React from "react";
import {useNavigate} from 'react-router-dom'

function SuccessAcceptance() {

    const  navigate = useNavigate()

    const printReceipt = (e) => {
        e.preventDefault()
        navigate('/admission/acceptance-receipt', {replace: true})
    }

  return (
    <>
      <center className="card" style={{ height: "100vh" }}>
        <div
          className="mt-5"
          style={{ color: "#45f248", fontFamily: "poppins" }}
        >
          <span>
            <i class="fa-solid fa-check" style={{ fontSize: "6rem" }}></i>
          </span>
          <h2 style={{ fontSize: "2rem" }}>Payment successful</h2>

          <div className="mt-5">
            <button
              style={{ width: "30%" }}
              className="btn btn-raised btn-default waves-effect"
              onClick={printReceipt}
            >
              Print Receipt
            </button>
          </div>
        </div>
      </center>
    </>
  );
}

export default SuccessAcceptance;
