import { numberWithCommas } from "../../../common"
import useAdmission from "../../../hooks/useAdmission"
import StudentInfo from "../../application/components/StudentInfo"
import "./Style.css"

const ApprovedResultComponent = ({loading, btnText, handlePayment, resultFee}) => {
    const {data} = useAdmission()
    return (
        <div>
            {data && 
            <div class="card p-5" style={{ padding: "10px" }}>
                  <div>
                    <p class="business">
                      {resultFee?.name && "Result checking payment"}
                    </p>
                    <p class="text">
                      {/* Please make the payment to continue your application process */}
                    </p>
                  </div>
                  <StudentInfo data={data}/>
                  <div class="pricing2 p-3 rounded mt-4 mb-4 d-flex justify-content-between">
                    <div class="images d-flex flex-row align-items-center">
                      <img
                        src="https://i.imgur.com/S17BrTx.png"
                        class="rounded"
                        width="60"
                        alt=""
                      />
                      <div class="d-flex flex-column ml-4">
                        <span class="business">Amount</span>
                        <span class="plan">{resultFee?.amountInWords}</span>
                      </div>
                    </div>
                    <div class="d-flex flex-row align-items-center">
                      <sup class="dollar font-weight-bold">₦</sup>
                      <span class="amount ml-1 mr-1">{resultFee && numberWithCommas(resultFee?.amount)}</span>
                    </div>
                  </div>
                  <span class="detail mt-5">Payment method</span>
                  <div class="credit rounded mt-4 d-flex justify-content-between align-items-center">
                    <div class="d-flex flex-row align-items-center">
                      <img
                        src="https://i.imgur.com/qHX7vY1.png"
                        class="rounded"
                        width="70"
                      />
                      <div class="d-flex flex-column ml-3">
                        <span class="business">Paystack</span>
                        {/* <span class="plan">1234 XXXX XXXX 2570</span> */}
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <button class="btn btn-success btn-block btn-raised waves-effect payment-button" onClick={handlePayment} disabled={loading}>
                      {btnText} <i class="fa fa-long-arrow-right"></i>
                    </button>
                  </div>
                </div>
            }
        </div>
      )
}

export default ApprovedResultComponent