import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { getCurrentUser } from "../../../components/services/auth/authService";
import api from "../../../api/api";
import { GetChangeOfCourseReceiptInfo } from "../../../api/admissions";

function AdmissionDashboard() {
  const [stage, setStage] = useState();
  const [student, setStudent] = useState();
  const [courseData, setCourseData] = useState();
  const [passport, setPassport] = useState();
  const [transaction, setTransaction] = useState({})
  const user = getCurrentUser();
  const navigate = useNavigate();

  const printReceipt = (e) => {
    e.preventDefault();
    navigate("/admission/acceptance-receipt");
  };

  const printResultVerificationReceipt = (e) => {
    e.preventDefault();
    navigate("/admission/result-payment/receipt");
  };
  const printChangeOfCourseReceipt = (e) => {
    e.preventDefault();
    navigate("/admission/change-of-course/receipt");
  };


  const getChangeOfCourseStatus = async () => {
    try {
      const {
        data: { transaction },
      } = await GetChangeOfCourseReceiptInfo();
      console.log("The transcation",transaction)
      setTransaction(transaction)
    } catch (error) {}
  };

  const currentStage = async () => {
    await api
      .get("/admissions/students/profile")
      .then((res) => {
        let data = res.data;
        console.log(data["data"]["student"]);
        setStudent(data["data"]["student"]);
        let stage = data["data"]["student"]["stage"];
        setStage(data["data"]["student"]["stage"]);
        setCourseData(data["data"]["course_data"]);
        setPassport(data["data"]["passport"]);

        if (stage === "change password") {
          navigate("/admission/password");
        } else if (stage === "bio-data") {
          navigate("/admission/biodata");
        } else if (stage === "payment") {
          navigate("/admission/acceptance");
        } else if (stage === "documents") {
          navigate("/admission");
        } else if (stage === "result verification fee") {
          navigate("/admission");
        } else if (stage === "completed") {
          navigate("/admission");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    currentStage();
    getChangeOfCourseStatus();
  }, []);

  return (
    <>
      <section class="content profile-page" style={{ borderRadius: "3px" }}>
        <div class="container-fluid">
          {stage === "documents" ? (
            <div class="block-header d-flex justify-flex-start align-items-center">
              <div className="d-flex justify-content-center align-items-center mr-3">
                <span className="mr-1">
                  <i class="fa-solid fa-check text-white g-bg-cgreen p-1  rounded-circle"></i>
                </span>
                <small className="text-muted">Change Password</small>
              </div>
              <div className="d-flex justify-content-center align-items-center mr-3">
                <span className="mr-1">
                  <i class="fa-solid fa-check text-white g-bg-cgreen p-1  rounded-circle"></i>
                </span>
                <small>BioData</small>
              </div>
              <div className="d-flex justify-content-center align-items-center mr-3">
                <span className="mr-1">
                  <i class="fa-solid fa-check text-white g-bg-cgreen p-1  rounded-circle"></i>
                </span>
                <small className="text-muted">Payment</small>
              </div>
              <div className="d-flex justify-content-center align-items-center mr-3">
                <span className="mr-1">
                  <i class="fa-solid fa-check text-white g-bg-cgreen p-1  rounded-circle"></i>
                </span>
                <small className="text-muted">Result verification fee</small>
              </div>
              <div className="d-flex justify-content-center align-items-center mr-3">
                <small>Document upload</small>
              </div>
            </div>
          ) : stage === "result verification fee" ? (
            <div class="block-header d-flex justify-flex-start align-items-center">
              <div className="d-flex justify-content-center align-items-center mr-3">
                <span className="mr-1">
                  <i class="fa-solid fa-check text-white g-bg-cgreen p-1  rounded-circle"></i>
                </span>
                <small className="text-muted">Change Password</small>
              </div>
              <div className="d-flex justify-content-center align-items-center mr-3">
                <span className="mr-1">
                  <i class="fa-solid fa-check text-white g-bg-cgreen p-1  rounded-circle"></i>
                </span>
                <small>BioData</small>
              </div>
              <div className="d-flex justify-content-center align-items-center mr-3">
                <span className="mr-1">
                  <i class="fa-solid fa-check text-white g-bg-cgreen p-1  rounded-circle"></i>
                </span>
                <small className="text-muted">Payment</small>
              </div>
              <div className="d-flex justify-content-center align-items-center mr-3">
                <small>Result verification fee</small>
              </div>
              <div className="d-flex justify-content-center align-items-center mr-3">
                <small className="text-muted">Document upload</small>
              </div>
            </div>
          ) : (
            ""
          )}
          <div class="block-header">
            <h2>Acceptance Dashboard</h2>
          </div>
          {stage === "completed" ? (
            <div class="alert alert-info" style={{ marginBottom: "2rem" }}>
              <strong>Congratulations! Acceptance process Completed.</strong>
            </div>
          ) : (
            ""
          )}
          {stage === "result verification fee" && (
            <div class="alert alert-warning" style={{ marginBottom: "2rem" }}>
              <strong>
                Please proceed to Result Verification Payment portal{" "}
                <span>
                  <Link to="/admission/result-payment">Click here</Link>
                </span>
              </strong>
            </div>
          )}

          {stage === "documents" && (
            <div class="alert alert-warning" style={{ marginBottom: "2rem" }}>
              <strong>
                Upload documents to conclude Acceptance process{" "}
                <span>
                  <Link to="/admission/upload">Click here</Link>
                </span>
              </strong>
            </div>
          )}
          {/* <div className="row clearfix">
            <div class="col-lg-6 col-md-6 col-sm-12">
              {stage === "documents" ? (
                <div class="info-box-3 bg-green" style={{ height: "120px" }}>
                  <div class="icon"></div>
                  <div class="content">
                    <div
                      class="text"
                      style={{ fontSize: "1.5rem", fontWeight: "600" }}
                    >
                      Acceptance Fee
                    </div>
                    <div class="number">PAID</div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              class="col-lg-6 col-md-6 col-sm-12"
              style={{ marginBottom: "3rem" }}
            >
              <div class="info-box-3 bg-blush" style={{ height: "120px" }}>
                <div class="icon"></div>
                <div class="content">
                  <div
                    class="text"
                    style={{ fontSize: "1.5rem", fontWeight: "600" }}
                  >
                    Registration
                  </div>
                  <div class="number">NO</div>
                </div>
              </div>
            </div>
          </div> */}
          <div class="row clearfix">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="card">
                <div style={{ height: "180px", width: "180px" }}>
                  <img
                    alt="passport"
                    src={passport}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <table class="table table-bordered table-striped table-hover js-basic-example dataTable">
                  <tbody>
                    <tr>
                      <td>Jamb Number</td>
                      <td>{student?.jamb_reg_number}</td>
                    </tr>
                    <tr>
                      <td>Candidate Name</td>
                      <td>
                        {student?.last_name +
                          " " +
                          student?.middle_name +
                          " " +
                          student?.first_name}
                      </td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{student?.email}</td>
                    </tr>
                    <tr>
                      <td>Telephone</td>
                      <td>{student?.phone}</td>
                    </tr>
                    <tr>
                      <td>Gender</td>
                      <td>{student?.gender}</td>
                    </tr>
                    <tr>
                      <td>Faculty</td>
                      <td>{courseData?.school}</td>
                    </tr>
                    <tr>
                      <td>Department</td>
                      <td>{courseData?.department}</td>
                    </tr>
                    <tr>
                      <td>Programme</td>
                      <td>{courseData?.programme}</td>
                    </tr>
                    <tr>
                      <td>Programme Type</td>
                      <td>{student?.program_type}</td>
                    </tr>
                    <tr>
                      <td>Acceptance Payment Status</td>
                      <td>
                        {student?.stage === "result verification fee" ||
                        student?.stage === "documents" ||
                        student?.stage === "completed"
                          ? "Paid"
                          : "Not Paid"}
                      </td>
                    </tr>
                    <tr>
                      <td>Result Verification Payment</td>
                      <td>
                        {student?.stage === "documents" ||
                        student?.stage === "completed"
                          ? "Paid"
                          : "Not Paid"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-lg-6 col-md-12 col-sm-12">
              <div className="card">
                <div className="col-lg-12">
                  <button
                    className="btn btn-raised btn-warning waves-effect"
                    onClick={printReceipt}
                  >
                    View Acceptance Payment Receipt
                  </button>
                  {stage === "documents" && (
                    <button
                      className="btn btn-raised btn-primary waves-effect"
                      onClick={printResultVerificationReceipt}
                    >
                      View Result Verification Payment Receipt
                    </button>
                  )}
                  {stage === "completed" && (
                    <button
                      className="btn btn-raised btn-primary waves-effect"
                      onClick={printResultVerificationReceipt}
                    >
                      View Result Verification Payment Receipt
                    </button>
                  )}
                  {Object.keys(transaction).length !== 0 && (
                    <button
                      className="btn btn-raised btn-warning waves-effect"
                      onClick={printChangeOfCourseReceipt}
                    >
                      View Change of Course Payment Receipt
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AdmissionDashboard;
