import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AdmissionStudentLogin } from "../../api/admissions";
import api from "../../api/api";
import Footer from "../../components/Footer";
import Logo from "../../components/Logo";
import { setCurrentUser } from "../../components/services/auth/authService";
import { asyncLocalStorage } from "../../utils/appParams";
import BACKGROUND from "./../../assets/images/background.png";
import BG from "./../../assets/images/bg2.jpeg";

function AdmissionLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navi = useNavigate();

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const checkLoggedIn = async () => {
    const token = await asyncLocalStorage.getItem("user-token");

    if (token) {
      getUser();
    }
  };

  useEffect(() => {
    checkLoggedIn();
  }, []);

  const loginApplicant = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      return toast.warning("Please provide login details", warnProp);
    }

    try {
      setLoading(true);
      const { access, refresh } = await AdmissionStudentLogin({
        username: email,
        password,
      });
      setLoading(false);
      await asyncLocalStorage.setItem("user-token", access);
      getUser();
      toast.success("Login Successful", successProp);

      window.location.reload(false);
    } catch (error) {
      console.log("The error is", error);
      if (error.status === "401" || "400") {
        setLoading(false);
        toast.error("Invalid username or password", errorProp);
      } else {
        setLoading(false);
        toast.error(error.data.detail, errorProp);
      }
    }
  };

  const getUser = () => {
    api
      .get("/admissions/students/profile")
      .then((res) => {
        let data = res.data;
        console.log(data["data"]["student"]);
        setCurrentUser(data["data"]["student"]);
        let stage = data["data"]["student"]["stage"];

        if (stage === "change password") {
          navi("/admission/password", {replace:true});
        } else if (stage === "bio-data") {
          navi("/admission/biodata", {replace:true});
        } else if (stage === "payment") {
          navi("/admission/acceptance", {replace:true});
        } else if (stage === "documents") {
          navi("/admission", {replace:true});
        } else if (stage === "result verification fee") {
          navi("/admission", {replace:true});
        } else if (stage === "completed") {
          navi("/admission", {replace:true});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div
      style={{
        backgroundImage: `url(${BACKGROUND})`,
        height: "100% !important",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div class="container" style={{ opacity: ".9" }}>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div class="card-top"></div>
        <div class="card" style={{ borderRight: "5px solid #0c0f2e" }}>
          <Logo />
          <h1 class="title" style={{ borderLeft: "5px solid #0c0f2e" }}>
            <span>Admission Portal</span>Login{" "}
            <span class="msg">Sign in to start your account</span>
          </h1>
          <div class="col-sm-12">
            <form id="sign_in">
              <div class="input-group">
                {" "}
                <span class="input-group-addon">
                  {" "}
                  <i class="zmdi zmdi-email"></i>{" "}
                </span>
                <div class="form-line">
                  <input
                    type="text"
                    class="form-control"
                    name="username"
                    value={email}
                    placeholder="Username"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    autofocus
                  />
                </div>
              </div>
              <div class="input-group">
                {" "}
                <span class="input-group-addon">
                  {" "}
                  <i class="zmdi zmdi-lock"></i>{" "}
                </span>
                <div class="form-line">
                  <input
                    type="password"
                    class="form-control"
                    name="password"
                    value={password}
                    placeholder="Password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <div class="">
                <input
                  type="checkbox"
                  name="rememberme"
                  id="rememberme"
                  class="filled-in chk-col-pink"
                />
                <label for="rememberme">Remember Me</label>
              </div>
              <div class="text-center">
                {loading ? (
                  <div
                    class="preloader pl-size-xs"
                    style={{ marginRight: "5px" }}
                  >
                    <div class="spinner-layer pl-red-grey">
                      <div class="circle-clipper left">
                        <div class="circle"></div>
                      </div>
                      <div class="circle-clipper right">
                        <div class="circle"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <button
                    class="btn btn-raised waves-effect g-bg-blush2"
                    onClick={loginApplicant}
                  >
                    SignIn
                  </button>
                )}
              </div>
            </form>
            <ToastContainer />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default AdmissionLogin;
