import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavHeader from "./components/NavHeader";

const AccountSuccess = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [applicant, setApplicant] = useState();

  useEffect(() => {console.log(state)},[])
  return (
    <>
    {state && 
    <>
      <NavHeader />
      <div class="container" style={{ marginTop: "5rem" }}>
        <div class="col-lg-12 align-self-center">
          <div className="card">
            <div className="card-body">
              <div class="text-center auth-logo-text">
                <h3 class="mt-0 mb-3 mt-5">
                  🥳Congratulations, Account Created!
                </h3>
                <hr />
                <h6 class="mb-0">
                  Your Application Number is: <b>{state?.id}</b>
                </h6>
                <h6 class="mb-0">
                  You can access your Application Number, password and other details
                  from your email.
                </h6>
                <h6 class="mb-0">
                  Please{" "}
                  <span
                    onClick={() => navigate("/apply/login", {  state: {state} })}
                    className="text-blue"
                    style={{ cursor: "pointer" }}
                  >
                    click here to login
                  </span>{" "}
                  and continue with other section of your application.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    }
    </>
  );
};

export default AccountSuccess;
