import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../api/api";

function ClearanceResetPassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const resetPassword = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      let {data} = await api.post("/students/change-password", {
        old_password: oldPassword,
        new_password: newPassword,
      });
      setLoading(false);
      console.log(data);
      toast.success(data.message, successProp);
      setNewPassword("")
      setOldPassword("")
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error.message, errorProp)
    }
  };

  return (
    <>
      <section className="content">
        <div class="container-fluid">
          <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="card">
                <div class="header">
                  <h2 style={{ fontSize: "1.2rem", fontWeight: "600" }}>
                    Provide your information
                  </h2>
                </div>
                <div class="body">
                  <form onSubmit={resetPassword}>
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="form-group">
                          <b>Old Password*</b>
                          <div class="form-line">
                            <input
                              type="password"
                              class="form-control"
                              placeholder="Enter your old password"
                              value={oldPassword}
                              onChange={(e) => setOldPassword(e.target.value)}
                              required
                            />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                      <div class="form-group">
                        <b>New Password*</b>
                        <div class="form-line">
                          <input
                            type="password"
                            class="form-control"
                            placeholder="Enter your new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      {loading ? (
                        <div
                          class="preloader pl-size-xs"
                          style={{ marginRight: "5px" }}
                        >
                          <div class="spinner-layer pl-red-grey center">
                            <div class="circle-clipper left">
                              <div class="circle"></div>
                            </div>
                            <div class="circle-clipper right">
                              <div class="circle"></div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <button
                          type="submit"
                          class="btn btn-raised g-bg-blush2"
                        >
                          Change Password
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ClearanceResetPassword;
