import { useEffect, useState } from "react";
import api from "../../../api/api";
import "./MarqueeStyle.css"

const NewsMarquee = () => {
  const [news, setNews] = useState()

  const getNews = async () => {
    try {
      const data = api.get('/setup/announcement/view')
        .then(res => setNews(res.data.data))
    } catch (error) {
      
    }
  }

  useEffect(() => {getNews()}, [])
  return (
    <div>
      <h4 className="mb-0">ANNOUCEMENT</h4>
      <div className="marquee-container blur">
        <div className="marquee-slider">
          {news?.map((item) => (
          <h6 key={item.id} className="text-danger">
            {item.body}
          </h6>
          ))}
        </div>
      </div>
    </div>
  )
}

export default NewsMarquee