import { useEffect, useState } from "react"
import { GetApplicantDetails } from "../api/application";
import { getCurrentUser } from "../components/services/auth/authService";

const useDetails = () => {
    const [data, setData] = useState()
    const [staging, setStaging] = useState()
    const [loading, setLoading] = useState(false);
    const user = getCurrentUser();

    useEffect(() => {
        const getDetails = async () => {
            setLoading(true);
            try {
              const { data } = await GetApplicantDetails(user.application_id);
              console.log("Details", data);
              setStaging(data.stage);
              setData(data)
              setLoading(false);
            } catch (error) {
              setLoading(false);
            }
          };
          getDetails()
    }, [staging])

    return {data, loading, staging}
}

export default useDetails